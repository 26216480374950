import React from "react";
import { isValid } from "date-fns";
import { inputMappings } from "../../../util/enums";
import DatePicker from "./DateUtil/DatePicker";
import TimePicker from "./DateUtil/TimePicker";
import DateTimeGroup from "./DateUtil/Group";

function DateTime(props = {}) {
  const {
    id,
    value:  currentValue,
    type,
    onChange,
    className,
    placeholder,
    startTime,
    endTime,
    validation,
    helperText,
    error,
    required = false,
    proxyAccess = false,
    ...rest
  } = props;

  let value = null;
  if (isValid(currentValue)) {
    value = currentValue;
  } else if (currentValue) {
    const parsedDate = new Date(currentValue);
    if (isValid(parsedDate)) {
      value = parsedDate;
    }
  }

  let sharedProps = {
    id,
    onChange: (value) => {
      onChange({
        id,
        value,
      });
    },
    className,
    value,
    ...rest,
  };
  if(type === inputMappings.datetime) {
    return <DateTimeGroup {...props} {...sharedProps} value={currentValue} proxyAccess={proxyAccess} />
  }
  if (type === inputMappings.time) {
    return <TimePicker {...sharedProps} slotProps={{ textField: { error: (!!error), helperText: (error ?? ""), required } }} />;
  }

  return <DatePicker {...sharedProps} slotProps={{ textField: { error: (!!error), helperText: (error ?? ""), required } }} />;
}

DateTime.defaultProps = {
  value: null,
};

export default DateTime;
