import { Mui, usePrevious } from "@osu/react-ui";
import React, { Fragment, useCallback, useEffect, useReducer, useRef } from "react";
import Sections from "./_Sections";
import { templateFormReducer } from "../util";

const Footer = (props = {}) => {
  const { showSubmit = true, onSubmit, onSave, state, submitProps, saveProps } = props
  let actions = []
  let handler = useCallback((changeEvent) => {
    changeEvent && changeEvent(state)
  }, [state])
  if(onSave) {
    actions.push({
      label: 'Save',
      color: 'primary',
      variant: 'outlined',
      className: (showSubmit === true ? 'margin-right-3' : undefined),
      ...saveProps,
      handler: () => handler(saveProps?.handler || onSave)
    })
  }
  if(showSubmit === true && onSubmit) {
    actions.push({
      label: 'Submit',
      color: 'primary',
      variant: 'contained',
      ...submitProps,
      handler: () => handler(submitProps?.handler || onSubmit)
    })
  }

  Footer.defaultProps = {
    submitProps: {},
    saveProps: {},
  }

  const MappedButtons = actions.map(({ label, handler, ...rest }, index) => <Mui.Button
    {...rest}
    onClick={() => handler(state)}
    key={`${index}-${label}-form-handler`}
  >
    {label}
  </Mui.Button>)

  if(!actions?.length) {
    return undefined
  }
  return <Mui.Box textAlign="right">
    {MappedButtons}
  </Mui.Box>
}

function StandardTemplate(props) {
  const {
    fetchingRequired,
    initialState,
    formSections,
    getFormValues,
    showSubmit,
    onSubmit,
    onSave,
    submitProps,
    saveProps,
    errorState,
    loading,
    setStudentInfoId,
    proxyAccess = false
  } = props;
  const withTOC = Object.prototype.hasOwnProperty.call(props || {}, 'withTOC')  ? props.withTOC : formSections.length > 2 
  const [stepState, dispatchStepState] = useReducer(
    templateFormReducer,
    initialState
    );

  const previousLoading = usePrevious(loading)
  const formRef = useRef()
  useEffect(() => {
    if(previousLoading && initialState && !errorState) {
      dispatchStepState({ 
        type:  "RESET", 
        payload: {
          initialState
        }
      })
    }
  }, [previousLoading, initialState, errorState])
  
  useEffect(() => {
    dispatchStepState({ 
      type:  "ERROR", 
      payload: {
        errorState
      }
    })
  }, [errorState])
  
  useEffect(() => {
    if (fetchingRequired) {
      getFormValues();
    }
  }, [fetchingRequired, getFormValues]);

  const handleSave = (event) => {
    onSave(event, stepState);
  }

  const handleSubmit = (event) =>  {
    const validity = formRef?.current && formRef.current.reportValidity()
    onSubmit(event, validity, stepState)
  }

  const Base = ({ width1 = "20%", width2 = "35%"}) => <Fragment>
    <Mui.Skeleton width={width1} />
    <Mui.Skeleton width={width2}  height="3rem" />
  </Fragment>


if(loading) {
    return <Mui.Box>
      <Mui.Skeleton className="margin-bottom-2" width="38%"  height="4rem" />
      <Base width1="18%" />
      <Base width2="25%" />
      <Mui.Skeleton className="margin-bottom-2" width="58%"  height="4rem" />
      <Base width1="28%" width2="45%" />
      <Base />
      <Base width1="15%" width2="40%" />
    </Mui.Box>
  }


  return (
    <Mui.Box ref={formRef} component="form" display="flex" flexDirection="column">
      <Sections formSections={formSections} withTOC={withTOC} dispatchStepState={dispatchStepState} stepState={stepState} setStudentInfoId={setStudentInfoId} proxyAccess={proxyAccess} />
      <Footer 
        showSubmit={showSubmit}
        onSubmit={handleSubmit}
        onSave={handleSave}
        state={stepState}
        submitProps={submitProps}
        saveProps={saveProps}
      />
    </Mui.Box>
  );
}

StandardTemplate.defaultProps = {
  formSections: []
};

export default StandardTemplate;
