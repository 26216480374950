import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import { LEAVE_REASON } from "../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    title: "Graduation Information",
    id: "graduation-information",
    questions: [
      {
        title: "Graduation Level",
        id: "graduation-information-level",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduationLevel,
        branch: "graduation-information-plan",
        dataField: "gradLevel"
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduatePrograms,
        filter: [
          {
            field:  "graduation-information-level",
            match: "gradLevel",
            operation: "includes"
          }
        ],
        branch: "advisor-info",
        dataField: "academicPlan",
        dependencyHelperText: "Graduation Level must be selected before choosing a Graduation Plan",
        dependencies: ["graduation-information-level"]
      }
    ]
  },
  {
    "id": "advisor",
    title: "Advisor Information",
    questions: [
      {
        id: "advisor-info",
        type: inputMappings.dropDown,
        title: "Advisor",
        dataField: "advisor",
        subtype: dropdownSubtypes.advisor,
        dependencies: ["graduation-information-plan"],
        dependencyHelperText: "Graduation Plan must be selected before choosing an Advisor",
        required: true
      }
    ]
  },
  {
    title: "Leave Details",
    id: "dates-of-absence",
    questions: [
      {
        id: "first-term-on-leave",
        type: inputMappings.dropDown,
        title: "First Term on Leave",
        subtype: dropdownSubtypes.term,
        dataField: "fromTermCode",
        filter: {
          showFromPresent: 5
        },
        required: true,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 1
      },
      {
        id: "last-term-on-leave",
        type: inputMappings.dropDown,
        title: "Last Term on Leave",
        dataField: "toTermCode",
        required: true,
        subtype: dropdownSubtypes.term,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 1,
        filter: {
          showFromPresent: 5
        }
      },
      {
        id: "reason-for-leave",
        type: inputMappings.radio,
        title: "Reason for Leave",
        summary: "(Student will need to attach supporting documentation, such as a doctor’s statement or military orders)",
        required: true,
        dataField: "reason",
        choices: [
          {
            id: "reason-medical",
            title: "Medical",
            value: LEAVE_REASON.MEDICAL
          },
          {
            id: "reason-military",
            title: "Military",
            value: LEAVE_REASON.MILITARY
          }
        ]
      },
      {
        id: "file-upload",
        type: inputMappings.fileUpload,
        title: "Attachment(s)",
        Description: () => (
          <Mui.Typography variant="body2" className="margin-bottom-1">
            The student should upload any medical or military support documentation as well as the progress report from the program.
          </Mui.Typography>
        ),
        required: false,
        dataField: "attachments",
        acceptedFileTypes: ["application/msword", ".doc", ".docx", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ".xls", ".xlsx", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "image/gif", ".gif",
          "image/png", ".png", "image/jpeg", ".jpeg", "image/bmp", ".bmp", "image/tiff", ".tiff", "text/plain", "application/pdf", ".txt"
        ],
        maxFileCount: 5,
        maxFileSize: 20000000 // 20MB
      },
      {
        id: "student-statement-justification",
        type: inputMappings.textInput,
        title: "Student Statement of Justification",
        multiline: true,
        summary: "The Statement of Justification should include at a minimum: How the completion of degree requirements is being impacted by the reason for a leave of absence and a confirmation of the intention to complete the degree upon the student’s return from their leave of absence.",
        required: false,
        dataField: "justification",
        rows: 5,
        inputProps: {
          maxLength: 2000
        }
      }
    ]
  },
  {
    title: "Acknowledgements",
    id: "acknowledgements",
    Component: () => (
      <Fragment>
        <Mui.Typography component="span" variant="h6">
          Student Acknowledgement of Expectations
        </Mui.Typography>
        <Mui.Typography>
          By approving this form, the student acknowledges that they will not use the time on a leave of absence to make progress toward the completion of any outstanding degree requirements. Should it be determined that the student made progress toward the degree during an approved leave of absence, the student affirms that they will be responsible for repayment of any back tuition or fees. The student also acknowledges that, during their leave of absence, they will be considered separated from the University and will not be eligible to access any academic resources. Further, the student acknowledges that their candidacy period will be paused during their leave of absence and will resume once the leave of absence has ended.
        </Mui.Typography>
        <Mui.Typography className="margin-top-2" component="span" variant="h6">
          Program Statement of Support
        </Mui.Typography>
        <Mui.Typography>
          By approving this form, the student’s advisor and graduate studies committee chair acknowledge their support of the student’s leave of absence request. They also confirm that they have discussed any potential consequences and impacts associated with the leave (e.g., lack of access to institutional resources, pause to candidacy, etc.), and that the student may not make progress toward their degree while on leave. Additionally, the advisor and graduate studies committee chair affirm their commitment to the student upon the student’s return to the University.
        </Mui.Typography>
        <Mui.Typography className="margin-top-2" component="span" variant="h6">
          Student Progress Report
        </Mui.Typography>
        <Mui.Typography>
        As part of this petition, the program is required to submit a report outlining the student’s progress to date. The progress report should include an overview of the student’s academic standing, progress made toward degree completion, and all outstanding degree requirements (e.g., remaining credits, courses, publications, scholarly performances or works, etc.) that the student must complete before receiving their degree. The student should attach the report along with other supporting documentation to this form.
        </Mui.Typography>
      </Fragment>
    )
  }
];