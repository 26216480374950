import { compact, find, isEmpty, reduce, values } from "lodash";
import { getFormByType } from "../Forms/paths";
import { ACTIVE, AFFILIATION, BOOL, ERROR_MESSAGE, EMAIL_REGEX, STATUS } from "../util/constants";
import { inputMappings } from "../util/enums";
import { deepCopy, isObject } from "../util/functions";
import { createValidationFields, DATE_ID, END_TIME_ID, START_TIME_ID } from "../Common/components/Inputs/DateUtil/util";

export function transformGraduateFacultyIfNeeded(graduateFaculty) {
    // const graduateFaculty = [];
    for(const faculty of graduateFaculty) {
        for(const appointment of graduateFaculty.appointments) {
            graduateFaculty.push({
                emplid: faculty.emplid,
                name: `${faculty.lastName}, ${faculty.firstName}`,
                email: faculty.email,
                phone: faculty.phone,
                affiliation: (AFFILIATION[appointment.affiliation] ?? ""),
                status: (appointment.active === ACTIVE.YES ? STATUS.ACTIVE : STATUS.INACTIVE),
                academicPlan: appointment.academicPlan,
                pending: appointment.pending,
                comments: appointment.comments
            });
        }
    }
    return graduateFaculty;
}

const reformatWithBodyKey = (dataFieldMapping, value) => {
  const keys = Object.keys(dataFieldMapping || {})
  if(dataFieldMapping && keys?.length) {
    let formattedValue = {}
    keys.forEach((key, index) => {
      let bodyKey = dataFieldMapping[key]
      const preparedValue = formatNestedValue(value?.[key])
      if(bodyKey) {
        formattedValue[bodyKey] = preparedValue
      } else {
        formattedValue = preparedValue
      }
    })
    return formattedValue
  }
  return value
}

const formatNestedValue = (value) => {
  const newValue = Object.prototype.hasOwnProperty.call(
    value || {},
    "value"
  )
    ? value.value
    : value;
    return newValue
}

export const createSkType = (dataField = "") => {
  let skType
  let splitsectionsFields = typeof dataField === "string" ? dataField?.split("#") : []
  let firstPart = splitsectionsFields[0]
  if (splitsectionsFields.length > 1) {
    splitsectionsFields.shift()
    skType = splitsectionsFields.join("#")
  }

  return {
    skType,
    dataField: firstPart || ""
  }
}

function formatValue(value, graphql, dataField) {
  const  { skType } = createSkType(dataField)
  let formattedValue = formatNestedValue(value)
  const dataFieldKeys = Object.keys(formattedValue?.dataFieldMapping || {})
  let restrictedObj = {}
  if(Array.isArray(graphql)) {
    graphql.forEach(({ dataField }) => {
      restrictedObj[dataField] = formattedValue[dataField]
    })
    formattedValue = restrictedObj
  }
  if(dataFieldKeys?.length) {
    formattedValue = reformatWithBodyKey(dataFieldKeys, value)
    return formattedValue
  }
  let valueArray = Array.isArray(formattedValue) ? formattedValue : Object.values(formattedValue || {}) 

  if(valueArray?.length && isObject(valueArray[0])) {
    let newValues = []
    valueArray.forEach(value => {
      const dataFieldMapping = value.dataFieldMapping ?? valueArray[0]?.dataFieldMapping

      let formattedValues = {}
      if(dataFieldMapping && Object.keys(dataFieldMapping)[0]) {
        formattedValues = reformatWithBodyKey(dataFieldMapping, value)
      }
      
      if(skType) formattedValues.skType = skType
      if(formattedValues && Object.keys(formattedValues).length) {
        newValues.push(formattedValues)
      }
    })
    return newValues
  }
  
  if(formattedValue === true) {
    formattedValue = BOOL.YES;
  } else if (formattedValue === false) {
    formattedValue = BOOL.NO;
  }

  if(Object.keys(formattedValue ?? {}).length && skType) {
    formattedValue.skType = skType
  }

  return formattedValue
}

function validateNumber(question, value) {
  const min = question?.inputProps?.min;
  const max = question?.inputProps?.max;
  const numberValue = +value;
  if(isNaN(numberValue)) return "Invalid number";
  if(Number.isInteger(min) && Number.isInteger(max) && (numberValue < min || numberValue > max)) {
    return `Number must be between ${min} and ${max}`;
  }
  if(Number.isInteger(min) && !Number.isInteger(max) && numberValue < min) {
    return `Number cannot be less than ${min}`;
  }
  if(!Number.isInteger(min) && Number.isInteger(max) && numberValue > max) {
    return `Number cannot be greater than ${max}`;
  }
}

export function transformFormDataToPayload(sections, formType, proxyAccess = false) {
  let body = {};
  let componentState = deepCopy({}, sections)
  const formDefinition = getFormByType(formType);

  let errors = [];
  Object.keys(sections).forEach((section) => {
    const formSection = find(formDefinition.sections, ["id", section]);
    Object.keys(sections[section] ?? {}).forEach((questionId) => {
      const formQuestion = find(formSection.questions, ["id", questionId]);
      const question = sections[section][questionId];
      const hasDot = (question?.dataField ?? "").includes(".")
      const hasHash = (question?.dataField ?? "").includes("#")
      const value = formatValue(question?.value, question?.graphql, (hasHash && question.dataField))
      if(formQuestion) {
        // don't transform unless dependencies are met
        const dependencies = (formQuestion?.dependencies ?? []);
        let transformQuestion = true;
        if(dependencies.length > 0) {
          for(const dependency of dependencies) {
            for(const q of formSection.questions) {
              if(q.id === dependency && q.choices?.length) {
                const c = find(q.choices, ["branch", questionId]);
                if(c) {
                  const matchingQ = sections[section][dependency];
                  if(matchingQ) {
                    const value = (matchingQ?.value?.value || matchingQ?.value);
                    if(value !== c.value) {
                      transformQuestion = false;
                      break;
                    }
                  }
                }
              }
            }
            if(!transformQuestion) break;
          }
        }

        if(transformQuestion) {
          if(!isEmpty(value) || typeof value === "number") { // isEmpty fails for numbers
            if(hasDot) {
              const splitsectionsFields = question.dataField.split(".")
              const parentField = splitsectionsFields[0];
              let reversed = splitsectionsFields.reverse();
              let nestedFields = {};
              reversed.forEach(function (field, idx, array) {
                let newObject = {
                  [field]: idx === 0 ? value : nestedFields,
                };
                nestedFields = newObject;

              });
              if (body?.[parentField]) {

                const computedCopy = deepCopy(
                  body[parentField],
                  nestedFields[parentField]
                );
                body[parentField] = computedCopy

              } else {
                body = {
                  ...body,
                  ...nestedFields,
                };
              }
            } else if(question?.dataField) {
              const dataField = (hasHash ? (question.dataField.split("#")[0]) : question.dataField)
              if(body[dataField] && Array.isArray(body[dataField])) {
                body[dataField] = body[dataField].concat(Array.isArray(value) ? value : [value])
              } else if (isObject(body[dataField])) {
                body[dataField] = {
                  ...body[dataField],
                  ...value
                }
              } else if(isObject(value)) {
                body[dataField] = reduce(value, (result, value, key) => {
                  if(key && value) { // check whether key and value exists (for example, DateTime without endDate will have undefined key and null value)
                    let updatedKey = key.replace(`${questionId}-`, "");
                    result[updatedKey] = value;
                  }
                  return result;
                }, {});
              } else {
                body[dataField] = value;
              }
            } else if(!question?.dataField && Array.isArray(value)) {
              value.forEach(item => {
                Object.keys(item).forEach(key => {
                  if(key) {
                    body[key] = item[key]
                  }
                })
              })
            }

            if(question?.transformValue) {
              Object.assign(body, question.transformValue(value))
            }

            if(formQuestion?.results?.length) {
              for(const result of formQuestion.results) {
                if(result?.dataField && result?.field) {
                  const resultValue = question?.value?.[result.field];
                  if(resultValue) body[result.dataField] = resultValue;
                }
              }
            }
          }

          if(formQuestion.required && isEmpty(value) && typeof value !== "number") { // isEmpty fails for numbers
            componentState[section][questionId].error = ERROR_MESSAGE.REQUIRED;
            errors.push(`${section}.${questionId}`);
          }

          if(formQuestion.type === inputMappings.numberInput && !isEmpty(value)) {
            const numberError = validateNumber(formQuestion, value);
            if(numberError) {
              componentState[section][questionId].error = numberError;
              errors.push(`${section}.${questionId}`);
            }
          }

          if(formQuestion.type === inputMappings.inputGroup) {
            const errorString = `${section}.${questionId}`;
            const inputGroupQuestions = formQuestion.questions;
            const questionValues = (typeof question.value === "object" ? values(question.value) : []);
            for(const questionValue of questionValues) {
              questionValue.errorMapping = {};
              const hasValue = compact(inputGroupQuestions.map((inputGroupQuestion) => (questionValue[inputGroupQuestion.id]))).length > 0;
              if(formQuestion.required === true || hasValue === true) {
                for(const inputGroupQuestion of inputGroupQuestions) {
                  if(inputGroupQuestion.required === true && isEmpty(questionValue[inputGroupQuestion.id])) {
                    questionValue.errorMapping[inputGroupQuestion.id] = ERROR_MESSAGE.REQUIRED;
                    if(!errors.includes(errorString)) errors.push(errorString);
                  }
                  if(inputGroupQuestion.type === inputMappings.email && !isEmpty(questionValue[inputGroupQuestion.id]) && 
                    !questionValue[inputGroupQuestion.id].match(EMAIL_REGEX)) {
                      questionValue.errorMapping[inputGroupQuestion.id] = `Invalid email address`;
                      if(!errors.includes(errorString)) errors.push(errorString);
                  }
                  if(inputGroupQuestion.type === inputMappings.numberInput && !isEmpty(questionValue[inputGroupQuestion.id])) {
                    const numberError = validateNumber(inputGroupQuestion, questionValue[inputGroupQuestion.id]);
                    if(numberError) {
                      questionValue.errorMapping[inputGroupQuestion.id] = numberError;
                      if(!errors.includes(errorString)) errors.push(errorString);
                    }
                  }
                }
              }
            }
          }
  
          if(formQuestion.type === inputMappings.checkbox && question.required === true) {
            const errorKey = `${section}.${questionId}`;
            if(question.value === BOOL.YES) {
              if(componentState[section]?.[questionId]?.error) componentState[section][questionId].error = "";
              const errorIndex = errors.indexOf(errorKey);
              if(errorIndex !== -1) errors.splice(errorIndex, 1);
            } else {
              componentState[section][questionId].error = ERROR_MESSAGE.REQUIRED;
              errors.push(errorKey);
            }
          }
  
          if(formQuestion.type === inputMappings.radio && question?.value?.branch) {
            for(const choice of formQuestion.choices) {
              if(!choice?.branch) {
                const isBranchChoice = (choice.id === question?.value?.branch);
                let choiceValue = sections[section]?.[choice.id]
                
                if(Object.prototype.hasOwnProperty.call(choiceValue || {}, 'value')) {
                  choiceValue = choiceValue.value
                }
                if(Object.prototype.hasOwnProperty.call(choiceValue || {}, 'value')) {
                  choiceValue = choiceValue.value
                }
  
                if(isBranchChoice && choiceValue) body[choice.dataField] = choiceValue;
                const errorKey = `${section}.${choice.id}`;
                if(isBranchChoice && choice.required === true) {
                  if(isEmpty(choiceValue)) {
                    if(!componentState[section]?.[choice.id]) componentState[section][choice.id] = choice;
                    componentState[section][choice.id].error = ERROR_MESSAGE.REQUIRED;
                    errors.push(errorKey);
                  }
                } else {
                  if(componentState[section]?.[choice.id]?.error) componentState[section][choice.id].error = "";
                  const errorIndex = errors.indexOf(errorKey);
                  if(errorIndex !== -1) errors.splice(errorIndex, 1);
                }
              }
            }
          }
  
          if(formQuestion.type === inputMappings.date) {
            const minDateId = formQuestion?.validation?.minDateId;
            if(minDateId && value) {
              const minDateFormQuestion = find(formSection.questions, ["id", minDateId]);
              if(minDateFormQuestion && minDateFormQuestion.type === inputMappings.date) {
                const minDateQuestion = sections[section][minDateId];
                if(minDateQuestion && minDateQuestion.value) {
                  if(!(value >= minDateQuestion.value)) {
                    componentState[section][questionId].error = `Must be on or after ${minDateFormQuestion.title}`;
                    errors.push(`${section}.${questionId}`);
                  }
                }
              }
            }
          }
  
          if(formQuestion.type === inputMappings.datetime) {
            const errorString = `${section}.${questionId}`;
            if(formQuestion.required === true) {
              const fieldIds = [DATE_ID];
              if(formQuestion.startTime === true) fieldIds.push(START_TIME_ID);
              if(formQuestion.endTime === true) fieldIds.push(END_TIME_ID);
              for(const fieldId of fieldIds) {
                if(isEmpty(question?.value?.[fieldId])) {
                  componentState[section][questionId].error = ERROR_MESSAGE.REQUIRED;
                  errors.push(errorString);
                  break;
                }
              }
            }
            // check for datetime specific errors that are already displayed and push the error
            if(!errors.includes(errorString)) {
              const { errors: validationErrors } = createValidationFields(question.value, formQuestion.validation, DATE_ID, START_TIME_ID, END_TIME_ID, proxyAccess);
              if(validationErrors.length > 0) {
                errors.push(errorString);
              }
            }
          }

          if(Number.isInteger(formQuestion.requiredResponses) && Array.isArray(value)) {
            if(value.length < formQuestion.requiredResponses) {
              componentState[section][questionId].error = `At least ${formQuestion.requiredResponses} responses are required.`;
              errors.push(`${section}.${questionId}`);
            }
          }
        }
      }
    });
  });
  
  return {
    body,
    errors,
    componentState
  };
}